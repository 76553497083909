import React from 'react';
import styles from './index.module.scss';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrderTrackingForm from './components/OrderTrackingForm';
import TrackingDetail from './components/TrackingDetail';
import Breadcrumbs from '../../features/Breadcrumb';

const OrderTracking = () => {
  const { t } = useTranslation();

  let currentRoute = useRouteMatch();
  const detailMatch = useRouteMatch('/order-tracking/detail');

  //render breadcrumb
  const breadcrumbNameMap = [
    { name: t('orderTracking.orderTracking'), path: '/order-tracking' },
    {
      name: t('orderTracking.orderDetail'),
      path: '/order-tracking/detail',
    },
  ];

  //component
  return (
    <div className={styles['container']}>
      <div className={styles['breadcrumb']}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} currentRoute={currentRoute} />
      </div>

      {detailMatch?.isExact ? (
        <TrackingDetail />
      ) : (
        <div className={styles['content']}>
          <OrderTrackingForm />
        </div>
      )}
    </div>
  );
};

export default OrderTracking;

import React from 'react';
// import { useWindowSize } from '@monorepo/hooks';
import { Image, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { renderVariantAttributes } from '@monorepo/utils';
import { useWindowSize } from '@monorepo/hooks';

function ProdTable({ orderDetails, rowSelection }) {
  const { t } = useTranslation();

  const { isMobileResponsive } = useWindowSize();

  const columns = [
    {
      title: t('orderTracking.image'),
      align: 'center',
      dataIndex: ['product', 'imageURL'],
      width: '18%',
      render: (_, record) => <Image width={80} height={80} src={record?.product?.imageURL} />,
    },
    {
      title: t('orderTracking.prodCode'),
      align: 'center',
      dataIndex: 'productCode',
      width: '15%',
    },
    {
      title: t('orderTracking.prodName'),
      align: 'center',
      dataIndex: 'productName',
      width: '20%',
    },
    {
      title: t('orderTracking.classify'),
      align: 'center',
      dataIndex: ['product', 'variantAttributeValues'],
      width: '20%',
      render: (_, record) => {
        const variantAttributes = record?.product?.variantAttributeValues;
        return renderVariantAttributes(variantAttributes);
      },
    },
    {
      title: t('orderTracking.quantity'),
      align: 'center',
      dataIndex: 'quantity',
      render: (_, record) =>
        `${record?.quantity} ${record?.product?.uom?.name ? record.product.uom.name : 'cái'}`,
    },
  ];

  const columnsMobile = [
    {
      dataIndex: ['productCode', 'productName', 'product', 'variantAttributeValues'],
      render: (_, record) => {
        const variantAttributes = record?.product?.variantAttributeValues;
        return (
          <div className={styles['mid']}>
            <div className={styles['title']}>{record.productName}</div>
            <i>{record.productCode}</i>
            <div>{renderVariantAttributes(variantAttributes)}</div>
          </div>
        );
      },
    },
    {
      dataIndex: ['quantity', 'product', 'imageURL'],
      render: (_, record) => {
        return (
          <div className={styles['right']}>
            <div className={styles['quantity']}>
              x {record.quantity} {record.product?.uom?.name || 'cái'}
            </div>
            <div>
              <Image width={80} height={80} src={record.product?.imageURL} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      rowSelection={rowSelection}
      columns={isMobileResponsive ? columnsMobile : columns}
      scroll={{ x: 'none', y: 390 }}
      dataSource={orderDetails}
      pagination={false}
      rowKey={(record) => record.productID}
      showHeader={!isMobileResponsive}
      className={`${styles['table-items']}`}
    />
  );
}

export default ProdTable;

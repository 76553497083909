import React from 'react';
import styles from './index.module.scss';
import logo from '~/assets/images/HAC-logo.png';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

const TheHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt="logo" onClick={() => history.push('/')} />
      <div className={styles.buttonGroup}>
        <a href="https://honganh.vn/lien-he" target="_blank" rel="noreferrer">
          <div className={`${styles['button']} ${styles['main-button']}`}>
            {t('header.contact')}
          </div>
        </a>
        <Link to="/order-tracking">
          <div className={`${styles['button']}`}>{t('header.tracking')}</div>
        </Link>
      </div>
    </div>
  );
};

export default TheHeader;

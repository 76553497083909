import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { createUploadLink } from 'apollo-upload-client/public/index';

const STATUS_UNAUTHENTICATED = 401;
const cache = new InMemoryCache({});

const GWHttpLink = createUploadLink({
  uri: process.env.REACT_APP_BASE_GRAPHQL_URL,
});

export const GWClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        if (networkError.statusCode === STATUS_UNAUTHENTICATED) {
          if (graphQLErrors && graphQLErrors.length > 0) {
            networkError.message = graphQLErrors[0]?.message;
          }
        }
        console.log(`[Network error]: ${JSON.stringify(networkError)} ${networkError.statusCode}`);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      }
    }),
    GWHttpLink,
  ]),
  cache,
  // defaultOptions: DEFAULT_OPTIONS,
});

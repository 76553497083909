import { gql } from '@apollo/client';

export const GET_ORDERS_QUERY = gql`
  query GetOrders(
    $filters: OrdersFilters!
    $pagination: PaginationRequest!
    $sort: [OrderSort!]
    $withOrderItems: Boolean!
  ) {
    order {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        orders {
          id
          createdAt
          number
          total
          subTotal
          customerPaymentMethod
          orderType
          codAmount
          exportedWarehouse {
            name
          }
          receiverContactName
          receiverContactAddress
          orderPriority
          shippingType
          paymentMethod
          status
          createdBy {
            fullname
            email
            telephone
          }
          fullStockRequired
          remainingDeliverTime
          vat
          promisedDeliverTime
          shippingConfiguration {
            busConfig {
              busName
              busStation
              telephone
              location
              fee
            }
            deliver3PartyConfig {
              name
              weight
              length
              width
              height
              fee
              serviceName
            }
            urbanConfig {
              fee
            }
          }
          internalNote
          stockStatus
          completedAt
          fileURLs
          senderAddress
          shipperTelephone
          shipperName
          receiverContactPhone
          note
          assemblyRequired
          shippingOrderCode
          items @include(if: $withOrderItems) {
            productCode
            productName
            quantity
            product {
              variantAttributeValues {
                attribute {
                  name
                }
                attributeValue {
                  value
                }
              }
              uom {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query {
    warehouse {
      list {
        warehouses {
          name
          id
        }
      }
    }
  }
`;

export const GET_WAREHOUSES_WITH_IDS = gql`
  query GetWarehouseWithIds($request: WarehouseRequest) {
    warehouse {
      list(request: $request) {
        warehouses {
          name
          id
        }
      }
    }
  }
`;

export const GET_CREATED_BY = gql`
  query GetCreatedBy($pagination: PaginationRequest) {
    user {
      pagination(pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        users {
          id
          email
        }
      }
    }
  }
`;

export const GET_ORDER_DETAIL_QUERY = gql`
  query GetOrder($id: ID!) {
    order {
      get(id: $id) {
        id
        status
        number
        vatTaxIDNumber
        vatCompanyName
        vatEmail
        vatAddress
        items {
          id
          productName
          productCode
          quantity
          price
          customerPrice
          total
          customerTotal
          maxDaysOfDebt
          productWarranty
          inStock
          vat
          product {
            uom {
              name
            }
          }
        }
        sellerID
        seller {
          shortName
          telephone
          address
          debtLimit
          availabilityLimit
          vatInfo {
            address
            email
            taxIDNumber
            businessName
          }
          companyWallet {
            balance
          }
        }
        createdByID
        note
        internalNote
        shippingType
        shippingConfiguration {
          busConfig {
            busName
            telephone
            location
            busStation
            fee
          }
          deliver3PartyConfig {
            name
          }
        }
        orderPriority
        promisedDeliverTime
        remainingDeliverTime
        fullStockRequired
        shippingFee
        serviceFee
        discount
        exportedWarehouseID
        vat
        discount
        subTotal
        subCustomerTotal
        customerTotal
        senderName
        senderPhone
        senderAddress
        receiverContactName
        receiverContactPhone
        receiverContactAddress
        total
        totalProduct
        cashPayment
        stockStatus
        paymentMethod
        assemblyRequired
        fileURLs
        fullEvents {
          state
          name
          createdAt
          createdBy {
            fullname
          }
        }
        orderType
        assemblyRequired
        customerPaymentMethod
      }
    }
  }
`;

export const GET_SUMMARY_TABS = gql`
  query GetSummaryTabs {
    order {
      totalByStatus {
        all
        waitingForApproval
        waitingForEnoughStock
        waitingForPayment
        waitingForPackaging
        waitingForExporting
        waitingForAssembly
        waitingForShipping
        delivering
        completed
        canceled
      }
    }
  }
`;

export const GET_CREATED_BY_WITH_IDS = gql`
  query GetCreatedByWithIds($filters: UsersFilters) {
    user {
      pagination(filters: $filters) {
        users {
          id
          email
        }
      }
    }
  }
`;

export const GET_COUNT_ORDER_WITH_FILTERS = gql`
  {
    waitingForShipping: order {
      count(
        filters: {
          statuses: [WAITING_FOR_SHIPPING]
          shippingTypes: [BUS, URBAN_COD, DELIVER_3PARTY]
        }
      )
    }
    deliveryWaiting: order {
      count(
        filters: {
          statuses: [DELIVERING]
          shippingStatus: WAITING
          shippingTypes: [BUS, URBAN_COD, DELIVER_3PARTY]
        }
      )
    }
    deliveryConfirmPicked: order {
      count(
        filters: {
          statuses: [DELIVERING]
          shippingStatus: CONFIRM_PICKED
          shippingTypes: [BUS, URBAN_COD, DELIVER_3PARTY]
        }
      )
    }
    completed: order {
      count(filters: { statuses: [COMPLETED], shippingTypes: [BUS, URBAN_COD, DELIVER_3PARTY] })
    }
  }
`;

import { SvgIcon } from '~/assets/svgs';
import React from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import logo from '~/assets/images/HAC-logo.png';

const TheFooter = () => {
  const { t } = useTranslation();

  const links = [
    { url: 'https://honganh.vn/quy-che-hoat-dong-san-tmdt', title: t('footer.rules') },
    { url: 'https://honganh.vn/chinh-sach-doi-tra/', title: t('footer.returnPolicy') },
    { url: 'https://honganh.vn/chinh-sach-bao-mat-2/', title: t('footer.privacyPolicy') },
    {
      url: 'https://honganh.vn/chinh-sach-thanh-toan/',
      title: t('footer.deliveryAndPaymentPolicy'),
    },
    {
      url: 'https://honganh.vn/chinh-sach-giai-quyet-khieu-nai/',
      title: t('footer.complaintsSettlementPolicy'),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img className={styles.icon} src={logo} alt="logo" />
        <div className={styles.subtitle}>{t('footer.logoSubtitle')}</div>
      </div>
      {/* <div className={styles.download}>
        <div className={styles['qr-code']}></div>
        <div className={styles['download-app']}>
          <SvgIcon.AppStoreSVG />
          <SvgIcon.GGstoreSVG />
        </div>
      </div> */}
      <div className={styles.policies}>
        {links.map(({ url, title }) => (
          <a href={url} target="_blank" rel="noreferrer" key={url}>
            {title}
          </a>
        ))}
      </div>
    </div>
  );
};

export default TheFooter;

import { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SvgIcon } from '../../../../assets/svgs';
import { getQuery, notify } from '@monorepo/utils';
import { useGetTrackingDetail } from '../../../../hooks/TrackingOrder';
import styles from './index.module.scss';
import { Spinner } from '@monorepo/shared-components';
import './index.scss';
import { useCreateOrderComplain } from '../../../../hooks/OrderComplain';
import FormRequired from './FormRequired';
import OrderDetail from './OrderDetail';
import OrderItems from './OrderItems';

const RequestInformationComplain = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valuesTable, setValuesTable] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const USE_QUERY = getQuery();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { handleCreateOrderComplain, loading: isLoading } = useCreateOrderComplain();

  const { data: orderDetails, loading } = useGetTrackingDetail({
    orderCode: location?.state?.orderCode,
    phoneNumber: location?.state?.phoneNumber,
  });

  const initForm = {
    reason: null,
    description: '',
    demand: null,
    total: null,
    paymentMethod: null,
    attachments: [],
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setValuesTable(selectedRowKeys);
    },
    selectedRowKeys: valuesTable,
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(() => {
        valuesTable.length
          ? showModal()
          : notify.warning({ message: t('orderComplain.notify.please_choose_item_complain') });
      })
      .catch((err) => {
        notify.error({
          message: t('orderComplain.notify.complain_fail'),
          description: err.message,
        });
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    const valuesForm = form.getFieldsValue(true);

    try {
      await handleCreateOrderComplain({
        auth: {
          orderCode: location?.state?.orderCode,
          phoneNumber: location?.state?.phoneNumber,
        },
        request: {
          itemIDs: valuesTable,
          ...valuesForm,
        },
      }).then(() => {
        history.push({
          pathname: '/order-tracking/detail/order-complain/review-complain',
          search: location.search,
          state: location.state,
        });
      });
    } catch (err) {
      notify.error({
        message: t('orderComplain.notify.complain_fail'),
        description: err.message,
      });
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!(location?.state?.orderCode || location?.state?.phoneNumber)) {
      history.replace({
        pathname: '/order-tracking/detail/order-complain',
        state: USE_QUERY,
      });
    }
    form.setFieldsValue(initForm);
  }, [JSON.stringify(initForm)]);

  return (
    <>
      <Spinner loading={loading}></Spinner>
      <Form layout="vertical" form={form} initialValues={initForm} onFinish={onFinish}>
        <div className={styles['container']}>
          <div className={`${styles['card']} ${styles['order-detail']}`}>
            <OrderDetail orderDetails={orderDetails} />
          </div>
          <div className={`${styles['card']} ${styles['order-items']}`}>
            <OrderItems rowSelection={rowSelection} orderDetails={orderDetails} />
          </div>
          <div className={`${styles['card']} ${styles['order-required']}`}>
            <FormRequired orderDetails={orderDetails} />
            {/* Button */}
            <div className={`${styles['group-button']}`}>
              <Button htmlType="submit" className={`${styles['button']} ${styles['main-button']}`}>
                {t('orderComplain.button.sendRequest')}
              </Button>
            </div>
          </div>
        </div>
      </Form>

      {/* Modal */}
      <Modal visible={isModalOpen} footer={false} closable={false}>
        <div className={`${styles['modal-popup']}`}>
          <div className={`${styles['icon']}`}>
            <span className={`${styles['icon']}`}>
              <SvgIcon.HelperSVG />
            </span>
          </div>
          <div className={`${styles['title']}`}>
            <ExclamationCircleOutlined style={{ color: 'red' }} />
            <span>{t('orderComplain.modal.title')}</span>
          </div>
          <div className={`${styles['description']}`}>{t('orderComplain.modal.description')}</div>
          <div className={`${styles['group-button']}`}>
            <Button onClick={handleCancel} className={`${styles['button']} ${styles['cancel']}`}>
              {t('orderComplain.button.cancel')}
            </Button>
            <Button
              onClick={handleOk}
              loading={isLoading}
              className={`${styles['button']} ${styles['confirm']}`}
            >
              {t('orderComplain.button.confirm')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RequestInformationComplain;

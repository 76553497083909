import { useMutation, useQuery } from '@apollo/client';
import { CREATE_ORDER_COMPLAIN } from '../../graphql/OrderComplain/mutation';
import { GET_DETAIL_COMPLAIN } from '../../graphql/OrderComplain/query';
import { useUpload } from '../upload';
import { omit } from 'lodash';

export const useGetDetailComplain = (params) => {
  const { loading, data, refetch } = useQuery(GET_DETAIL_COMPLAIN, {
    variables: params,
  });
  return {
    loading,
    refetch,
    data: data?.order?.getComplain,
  };
};

export const useCreateOrderComplain = () => {
  const [createOrderComplain, { loading, error }] = useMutation(CREATE_ORDER_COMPLAIN);
  const { handleUpload, loading: loadingUpload } = useUpload();
  const handleCreateOrderComplain = async (params) => {
    const uploadDocument = await handleUpload({
      files: params?.request?.attachmentURLs?.map((item) => item?.originFileObj),
    });
    const urlsDocument = uploadDocument.map((item) => item.url);
    const param = omit(params.request, ['attachments']);

    return await createOrderComplain({
      variables: { ...params, request: { ...param, attachmentURLs: [...urlsDocument] } },
    });
  };
  return {
    handleCreateOrderComplain,
    loading: loading || loadingUpload,
    error,
  };
};

import React from 'react';
import styles from './index.module.scss';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RequestInformationComplain from './components/RequestInformationComplain';
import Breadcrumbs from '../../features/Breadcrumb';

const OrderComplain = () => {
  const { t } = useTranslation();

  let currentRoute = useRouteMatch();
  const detailMatch = useRouteMatch('/order-tracking/detail/order-complain');

  //render breadcrumb
  const breadcrumbNameMap = [
    { name: t('orderTracking.orderTracking'), path: '/order-tracking' },
    {
      name: t('orderTracking.orderDetail'),
      path: '/order-tracking/detail',
    },
    {
      name: t('orderComplain.title.orderComplain'),
      path: '/order-tracking/detail/order-complain',
    },
  ];

  //component
  return (
    <div className={styles['container']}>
      <div className={styles['breadcrumb']}>
        <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} currentRoute={currentRoute} />
      </div>

      <div className={styles['content']}>{detailMatch && <RequestInformationComplain />}</div>
    </div>
  );
};

export default OrderComplain;

import React from 'react';
import styles from './index.module.scss';
import './index.scss';
import { Steps } from 'antd';
import { parseIsoDateStringToMoment, parseEventStatus } from '@monorepo/utils';
import { useWindowSize } from '@monorepo/hooks';
import { STATUS_ORDER, ANTD_STEPS_STATUS, DATE_TIME_FORMAT } from '@monorepo/config';

const { Step } = Steps;

const OrderEvents = ({ orderDetails }) => {
  const { isMobileResponsive } = useWindowSize();

  const currentAddressStatus = (status) => {
    if (status === STATUS_ORDER.COMPLETED || status === STATUS_ORDER.CANCELED) return 1;
    else return 0;
  };

  const renderCreateAt = (timestamp, isMobileResponsive) => {
    if (!timestamp) return;
    const timeString = parseIsoDateStringToMoment(timestamp).format(DATE_TIME_FORMAT).toString();

    const timestampMobile = timeString.split(' ');
    if (isMobileResponsive) {
      return timeString;
    } else {
      return timestampMobile.map((item, index) => <div key={index}>{item}</div>);
    }
  };
  return (
    <>
      {!isMobileResponsive && (
        <>
          <Steps
            progressDot
            responsive={false}
            status={orderDetails?.status === STATUS_ORDER.CANCELED ? 'error' : 'process'}
            current={currentAddressStatus(orderDetails?.status)}
          >
            <Step title={<div className={styles['address']}>{orderDetails?.senderAddress}</div>} />
            <Step
              title={
                <div className={styles['address']}>{orderDetails?.receiverContactAddress}</div>
              }
            />
          </Steps>
          <div style={{ padding: 10 }}></div>
        </>
      )}
      <div className={styles['event-steps']}>
        <Steps
          direction="vertical"
          labelPlacement="vertical "
          size={isMobileResponsive ? 'small' : 'default'}
          status={
            orderDetails?.status === STATUS_ORDER.CANCELED ? ANTD_STEPS_STATUS.ERROR : undefined
          }
          current={orderDetails?.fullEvents?.length - 1}
        >
          {orderDetails?.fullEvents?.map((event, index) => (
            <Step
              status={
                orderDetails?.status === STATUS_ORDER.CANCELED
                  ? undefined
                  : parseEventStatus(event.state)
              }
              key={index}
              title={<span className={styles['title']}>{event.name}</span>}
              description={
                <span className={styles['description']}>
                  {renderCreateAt(event.createdAt, isMobileResponsive)}
                </span>
              }
            />
          ))}
        </Steps>
      </div>
    </>
  );
};

export default OrderEvents;

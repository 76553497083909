import { formatCurrency } from '@monorepo/utils';
import React from 'react';
import { Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomCard from '../CustomCard';
import styles from './index.module.scss';
import { convertPaymentMethod, SHIPPING_TYPE } from '../../../ReviewComplain/constant';
import { convertDeliveryMethod } from '../../../../utils/helperFunc';

function OrderDetail({ orderDetails }) {
  const { t } = useTranslation();

  const communicator = {
    title: t('orderComplain.subTitle.infoReceiver'),
    name: orderDetails?.senderName,
    phone: orderDetails?.senderPhone,
    address: orderDetails?.senderAddress,
  };

  const orderInfo = {
    title: t('orderComplain.subTitle.infoOrder'),
    orderCode: orderDetails?.number,
    shippingType: orderDetails?.shippingType,
    shippingConfiguration: orderDetails?.ShippingConfiguration,
    paymentMethod: orderDetails?.paymentMethod,
    orderValue: orderDetails?.total,
  };


  return (
    <CustomCard
      title={<span className={styles['title-card']}>{t('orderComplain.title.orderComplain')}</span>}
    >
      <div className={styles['wrap-content']}>
        <div>
          <div className={`${styles['sub-title']} ${styles['mt-10']}`}>{communicator.title}</div>
          <div className={`${styles['content-text']}`}>{communicator.name}</div>
          <div className={`${styles['content-text']}`}>{communicator.phone}</div>
          <div className={`${styles['content-text']}`}>{communicator.address}</div>
        </div>
        <Divider className={`${styles['line']}`} />
        <div>
          <div className={`${styles['sub-title']} ${styles['mt-10']}`}>{orderInfo.title}</div>
          <Row gutter={[20]}>
            <Col>
              <div className={`${styles['content-text']}`}>{t('orderComplain.mdh')}:</div>
              <div className={`${styles['content-text']}`}>{t('orderComplain.gtdh')}:</div>
              <div className={`${styles['content-text']}`}>{t('orderComplain.dvvc')}:</div>
              <div className={`${styles['content-text']}`}>{t('orderComplain.pttt')}:</div>
            </Col>
            <Col>
              <div className={`${styles['content-text']}`}>{orderInfo.orderCode}</div>
              <div className={`${styles['order-value']} ${styles['content-text']}`}>
                {formatCurrency(orderInfo.orderValue)}
              </div>
              <div className={`${styles['content-text']}`}>
                {t(
                  convertDeliveryMethod(
                    orderDetails?.shippingType,
                    orderDetails?.shippingConfiguration
                  )
                ) || t('orderTracking.no')}
              </div>
              <div className={`${styles['content-text']}`}>
                {t(convertPaymentMethod(orderInfo.paymentMethod))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </CustomCard>
  );
}

export default OrderDetail;

import React from 'react';
import { TheContent, TheFooter, TheHeader } from '~/containers';
import styles from './index.module.scss';

const TheLayout = () => {
  return (
    <>
      <TheHeader />
      <div className={styles.container}>
        <TheContent />
      </div>
      <TheFooter />
    </>
  );
};

export default TheLayout;

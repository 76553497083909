import React from 'react';
import { Col, Image, Row } from 'antd';
import { t } from 'i18next';
import ComplainEvents from '../ComplainEvents';
import styles from './index.module.scss';
import ProdTable from '../../../OrderComplain/components/ProdTable';
import { useGetDetailComplain } from '../../../../hooks/OrderComplain';
import { useLocation } from 'react-router-dom';
import { formatCurrency, renderCreateAt } from '@monorepo/utils';
import { Spinner } from '@monorepo/shared-components';
import { useWindowSize } from '@monorepo/hooks';
import {
  convertDemandMethod,
  convertPaymentMethod,
  convertReasonMethod,
  convertStatusMethod,
} from '../../constant';
import { split } from 'lodash';

function ComplainDetail() {
  const location = useLocation();

  const { isMobileResponsive } = useWindowSize();

  const { data: getComplain, loading } = useGetDetailComplain({
    auth: {
      orderCode: location?.state?.orderCode,
      phoneNumber: location?.state?.phoneNumber,
    },
  });

  const complainDetail = {
    requestID: getComplain?.id,
    orderID: getComplain?.code,
    timeStamp: getComplain?.createdAt,
    statusComplain: getComplain?.status,
    requestContent: {
      request: getComplain?.demand,
      reason: getComplain?.reason,
      refundMoney: getComplain?.total,
      refundMethod: getComplain?.paymentMethod,
      detailRequest: getComplain?.description,
      imageVideo: getComplain?.attachmentURLs,
    },
    items: getComplain?.items,
    fullEvents: [
      {
        name: t('orderComplain.evenstComplain.send'),
        state: '',
        createdAt: getComplain?.complainEvents[0]?.createdAt,
      },
      {
        name: t('orderComplain.evenstComplain.receive'),
        state: '',
        createdAt: getComplain?.complainEvents[1]?.createdAt,
      },
      {
        name: t('orderComplain.evenstComplain.processing'),
        state: '',
        createdAt: getComplain?.complainEvents[2]?.createdAt,
      },
      {
        name: t('orderComplain.evenstComplain.completed'),
        state: '',
        createdAt: getComplain?.complainEvents[3]?.createdAt,
      },
    ],
  };

  const convertRequestID = (id) => {
    return `#KNHA${split(id, '_')[1]}`;
  };

  const isVideoUrl = (url) => {
    return url.includes('.mp4');
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>
      <div className={styles['container']}>
        <div className={`${styles['card']} ${styles['card-1']}`}>
          <div className={`${styles['title']}`}>
            {t('orderComplain.title.detailRequestComplain')}
          </div>
          <Row justify="space-between" className={`${styles['wrap-content']}`}>
            <Col
              className={`${styles['info-complain']} ${styles['card-item']} ${styles['content-text']}`}
            >
              <Row className={`${styles['text-info']}`}>
                <p className={``}>
                  {t('orderComplain.requestNumber')}
                  <span className={`${styles['text-bold']} ${styles['text-blue']}`}>
                    {convertRequestID(complainDetail.requestID)}
                  </span>
                  {t('orderComplain.ofOrder')}
                  <span className={`${styles['text-bold']} ${styles['text-blue']}`}>
                    {complainDetail.orderID}
                  </span>
                </p>
              </Row>
              <Row gutter={[16]} className={`${styles['text-info']}`}>
                <Col>
                  <p>{t('orderComplain.createAt')}</p>
                  <p>{t('orderComplain.status')}</p>
                </Col>
                <Col>
                  <p className={`${styles['text-bold']}`}>
                    {renderCreateAt(
                      complainDetail.timeStamp,
                      `${isMobileResponsive ? isMobileResponsive : !isMobileResponsive}`
                    )}
                  </p>
                  <p className={`${styles['text-bold']}`}>
                    {t(convertStatusMethod(complainDetail.statusComplain))}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col className={`${styles['tracking-event']} ${styles['card-item']} `}>
              <ComplainEvents orderDetails={complainDetail} />
            </Col>
          </Row>
        </div>

        <div className={`${styles['card']} ${styles['card-2']}`}>
          <div className={`${styles['title']}`}>{t('orderComplain.title.contentRequest')}</div>
          <div
            className={`${styles['content-request']} ${styles['card-item']} ${styles['wrap-content']}`}
          >
            <Row gutter={[16]} className={`${styles['text-info']} ${styles['content-text']}`}>
              <Col>
                <Row justify="space-between">
                  <Col>
                    <p className={`${styles['text-bold']}`}>{t('orderComplain.form.request')}</p>
                  </Col>
                  <Col>
                    <p>{t(convertDemandMethod(complainDetail.requestContent.request))}</p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <p className={`${styles['text-bold']}`}> {t('orderComplain.form.reason')}</p>
                  </Col>
                  <Col>
                    <p>{t(convertReasonMethod(complainDetail.requestContent.reason))}</p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <p className={`${styles['text-bold']}`}> {t('orderComplain.form.aom2')}</p>
                  </Col>
                  <Col>
                    <p>{formatCurrency(complainDetail.requestContent.refundMoney)}</p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <p className={`${styles['text-bold']}`}>
                      {' '}
                      {t('orderComplain.form.refundMethod')}
                    </p>
                  </Col>
                  <Col>
                    <p>{t(convertPaymentMethod(complainDetail.requestContent.refundMethod))}</p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <p className={`${styles['text-bold']}`}> {t('orderComplain.form.detail')}</p>
                  </Col>
                  <Col>
                    <p>{complainDetail.requestContent.detailRequest}</p>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col className={`${styles['assets']}`}>
                    <p className={`${styles['text-bold']}`}> {t('orderComplain.form.Assets')}</p>
                    <div className={`${styles['assets-list']}`}>
                      {complainDetail.requestContent.imageVideo?.map((item, index) => (
                        <div className={`${styles['img-video']}`} key={index}>
                          {isVideoUrl(item) ? (
                            <video width="180" controls>
                              <source src={item} type="video/mp4" />
                            </video>
                          ) : (
                            <Image width={100} height={100} src={item} preview />
                          )}
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>

        <div className={`${styles['card']} ${styles['card-3']}`}>
          <div className={`${styles['title']}`}>{t('orderComplain.title.prodComplain')}</div>
          <div className={`${styles['items-complain']} ${styles['card-item']}`}>
            <div className={styles['items-table']}>
              <ProdTable orderDetails={complainDetail?.items} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComplainDetail;

import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './index.module.scss';

function Breadcrumbs({ breadcrumbNameMap, currentRoute }) {
  const { t } = useTranslation();
  const location = useLocation();

  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      const crumbName = breadcrumbNameMap.find((item) => item.path === url)?.name;

      if (crumbName)
        return (
          <Breadcrumb.Item key={url}>
            {url === currentRoute?.path ? (
              <span
                className={`${styles['crumb']} ${
                  url === currentRoute?.path ? styles['current'] : ''
                }`}
              >
                {crumbName}
              </span>
            ) : (
              <Link to={url} className={`${styles['crumb']}`}>
                {crumbName}
              </Link>
            )}
          </Breadcrumb.Item>
        );
      else return false;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <span className={styles['crumb']}>{t('orderTracking.home')}</span>
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return <Breadcrumb separator={<RightOutlined />}>{breadcrumbItems}</Breadcrumb>;
}

export default Breadcrumbs;

import React from 'react';
import { REGEX } from '@monorepo/config';
import { Col, Form, Input, InputNumber, Row, Select, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomCard from '../CustomCard';
import { SvgIcon } from '../../../../assets/svgs';
import styles from './index.module.scss';
import { dummyRequest } from '@monorepo/utils';

function FormRequired() {
  const { t } = useTranslation();

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const optionsReason = [
    {
      value: 'LOW_QUALITY',
      title: t('orderComplain.options.reason.LOW_QUALITY'),
    },
    {
      value: 'WRONG_ATTRIBUTE',
      title: t('orderComplain.options.reason.WRONG_ATTRIBUTE'),
    },
    {
      value: 'BROKEN',
      title: t('orderComplain.options.reason.BROKEN'),
    },
    {
      value: 'FAKE',
      title: t('orderComplain.options.reason.FAKE'),
    },
    {
      value: 'OUT_DATE',
      title: t('orderComplain.options.reason.OUT_DATE'),
    },
    {
      value: 'ERROR',
      title: t('orderComplain.options.reason.ERROR'),
    },
    {
      value: 'SECOND_HAND',
      title: t('orderComplain.options.reason.SECOND_HAND'),
    },
    {
      value: 'DELAY_DELIVER',
      title: t('orderComplain.options.reason.DELAY_DELIVER'),
    },
    {
      value: 'INSUFFICIENT',
      title: t('orderComplain.options.reason.INSUFFICIENT'),
    },
    {
      value: 'WARRANTY_REQUIRED',
      title: t('orderComplain.options.reason.WARRANTY_REQUIRED'),
    },
  ];

  const optionsRequest = [
    {
      value: 'SWAP',
      title: t('orderComplain.options.request.SWAP'),
    },
    {
      value: 'REFUND_A_PART',
      title: t('orderComplain.options.request.REFUND_A_PART'),
    },
    {
      value: 'BACK_UP_AND_REFUND',
      title: t('orderComplain.options.request.BACK_UP_AND_REFUND'),
    },
    {
      value: 'WARRANTY',
      title: t('orderComplain.options.request.WARRANTY'),
    },
  ];

  const optionsRefundMethod = [
    {
      value: 'CASH',
      title: t('orderComplain.options.refundMethod.CASH'),
    },
    {
      value: 'DEBT',
      title: t('orderComplain.options.refundMethod.DEBT'),
    },
  ];

  return (
    <CustomCard
      title={
        <span className={`${styles['title-card']}`}>{t('orderComplain.title.infoRequest')}</span>
      }
    >
      <Row className={`${styles['wrap-content']}`}>
        <Col xl={{ span: 9 }} className={`${styles['form-left']}`}>
          <Form.Item
            name="reason"
            label={<div className={`${styles['sub-title']}`}>{t('orderComplain.form.reason')}</div>}
            rules={[
              {
                required: true,
                message: t('orderComplain.rules.reason'),
              },
            ]}
          >
            <Select
              bordered={false}
              className={`${styles['input-order-required']} ${styles['content-text']}`}
              placeholder={t('orderComplain.form.placeholder.reason')}
              dropdownStyle={{ borderRadius: '8px' }}
            >
              {optionsReason.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            label={
              <div className={`${styles['sub-title']}`}>
                {t('orderComplain.form.detailDescription')}
              </div>
            }
          >
            <Input.TextArea
              className={`${styles['input-order-required']} ${styles['content-text']}`}
              rows={4}
              placeholder={t('orderComplain.form.placeholder.detailDescription')}
              onResize={false}
            />
          </Form.Item>
          <Form.Item
            name="demand"
            label={
              <div className={`${styles['sub-title']}`}>{t('orderComplain.form.request')}</div>
            }
            rules={[
              {
                required: true,
                message: t('orderComplain.rules.demand'),
              },
            ]}
          >
            <Select
              className={`${styles['input-order-required']} ${styles['content-text']}`}
              placeholder={t('orderComplain.form.placeholder.request')}
              bordered={false}
              dropdownStyle={{ borderRadius: '8px' }}
            >
              {optionsRequest.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Row className={`${styles['group-refund']}`} gutter={[16, 16]}>
            <Col>
              <Form.Item
                name="total"
                label={
                  <div className={`${styles['sub-title']}`}>{t('orderComplain.form.aom')}</div>
                }
                rules={[
                  {
                    required: true,
                    message: t('orderComplain.rules.total'),
                  },
                ]}
              >
                <InputNumber
                  className={`${styles['input-order-required']} ${styles['content-text']}`}
                  style={{ width: '100%' }}
                  min={0}
                  maxLength={11}
                  placeholder="0"
                  formatter={(value) => value.replace(REGEX.CURRENCY, '.')}
                  parser={(value) => value.replace(REGEX.CURRENCY_PARSER, '')}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="paymentMethod"
                label={
                  <div className={`${styles['sub-title']}`}>
                    {t('orderComplain.form.refundMethod')}
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: t('orderComplain.rules.paymentMethod'),
                  },
                ]}
              >
                <Select
                  className={`${styles['input-order-required']} ${styles['content-text']}`}
                  placeholder={t('orderComplain.form.placeholder.refundMethod')}
                  bordered={false}
                  dropdownStyle={{ borderRadius: '8px' }}
                >
                  {optionsRefundMethod.map((item) => (
                    <Select.Option value={item.value} key={item.value}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xl={{ span: 12 }} className={`${styles['form-right']}`}>
          <Form.Item
            label={
              <div className={`${styles['sub-title']}`}>{t('orderComplain.form.addAssets')}</div>
            }
            rules={[
              {
                required: true,
                message: t('orderComplain.rules.attachmentURLs'),
              },
            ]}
            name="attachmentURLs"
            valuePropName="fileList"
            getValueFromEvent={getFile}
          >
            <Upload
              listType="picture-card"
              customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
              className={`${styles['upload-complain']}`}
              maxCount={9}
              multiple
            >
              <div>
                <span className={`${styles['icon']}`}>
                  <SvgIcon.ImgSVG />
                </span>
                <p style={{ fontSize: '12px' }}>{t('orderComplain.form.selectPaV')}</p>
              </div>
            </Upload>
          </Form.Item>
          <p className={`${styles['content-text']}`} style={{ color: '#9CADC4' }}>
            {t('orderComplain.form.note')}
          </p>
          {/* note */}
          <>
            <p>
              <span className={`${styles['text-red']}`}>{t('orderComplain.actention.1')}</span>
              {t('orderComplain.actention.2')}
              <span className={`${styles['text-red']}`}>{t('orderComplain.actention.3')}</span>
              {t('orderComplain.actention.4')}
            </p>
            <p className={`${styles['required']}`}>{t('orderComplain.form.actention.1')}</p>
            <p className={`${styles['required']}`}>{t('orderComplain.form.actention.2')}</p>
            <p className={`${styles['required']}`}>{t('orderComplain.form.actention.3')}</p>
            <p>{t('orderComplain.form.actention.4')}</p>
            <p>{t('orderComplain.form.actention.5')}</p>
          </>
        </Col>
      </Row>
    </CustomCard>
  );
}

export default FormRequired;

import React, { useRef, useState } from 'react';
import styles from './index.module.scss';
import { Button, Form } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import dropShippingImg from '../../../../assets/images/dropshipping.jpg';
import { useWindowSize } from '@monorepo/hooks';
import { notify, validator } from '@monorepo/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyGetTrackingDetail } from '../../../../hooks/TrackingOrder';
import queryString from 'query-string';

const OrderTrackingForm = () => {
  const reCaptChaRef = useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { isFoldedPhone } = useWindowSize();

  const { getOrderDetail } = useLazyGetTrackingDetail();

  const [orderCode, serOrderCode] = useState(location?.state?.orderCode || '');
  const [phoneNum, serPhoneNum] = useState('');
  const [captChaToken, setCaptChaToken] = useState(null);

  const [form] = Form.useForm();

  const handleTrackSubmit = async () => {
    const formValues = { orderCode: orderCode, phoneNumber: phoneNum };

    await getOrderDetail({ variables: formValues })
      .then((res) => {
        const newOrderDetails = res.data.order.getByCodeAndPhone;
        const paramsString = queryString.stringify({ orderCode: formValues.orderCode });

        if (newOrderDetails) {
          history.push({
            pathname: '/order-tracking/detail',
            search: paramsString,
            state: formValues,
          });
        } else {
          notify.error({
            message: t('orderTracking.orderNotFound'),
          });
        }
      })
      .catch((error) => {
        notify.error({
          message: t('orderTracking.orderTrackingError'),
          description: error?.message,
        });
      });
  };

  const handleCaptChaChange = (value) => {
    setCaptChaToken(value);
  };

  return (
    <div className={styles['container']}>
      <Form
        form={form}
        onFinish={handleTrackSubmit}
        initialValues={{ 'order-code': orderCode, 'phone-number': phoneNum, captcha: captChaToken }}
      >
        <div className={styles['form']}>
          <div className={styles['title']}>{t('orderTracking.form.title')}</div>
          <div className={styles['note']}>{t('orderTracking.form.note')}</div>
          <div className={styles['input-field']}>
            <div className={styles['label']}>{t('orderTracking.form.orderCodeLabel')} (*)</div>
            <Form.Item
              name="order-code"
              rules={[
                {
                  required: true,
                  message: t('orderTracking.form.validateBlankOrderCode'),
                },
              ]}
            >
              <input
                name="order-code"
                className={styles['input']}
                placeholder={t('orderTracking.form.orderCodePlaceholder')}
                value={orderCode}
                onChange={(e) => {
                  serOrderCode(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          <div className={styles['input-field']}>
            <div className={styles['label']}>{t('orderTracking.form.phoneNumLabel')} (*)</div>
            <Form.Item
              name="phone-number"
              rules={[
                {
                  required: true,
                  message: t('orderTracking.form.validateBlankPhoneNumber'),
                },
                validator({
                  type: 'phone',
                }),
              ]}
            >
              <input
                name="phone-number"
                className={styles['input']}
                placeholder={t('orderTracking.form.phoneNumPlaceholder')}
                value={phoneNum}
                onChange={(e) => {
                  serPhoneNum(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          <div className={styles['reCaptcha']}>
            <Form.Item
              name="captcha"
              rules={[
                {
                  required: true,
                  message: t('orderTracking.form.validateBlankReCaptcha'),
                },
              ]}
            >
              <ReCAPTCHA
                ref={reCaptChaRef}
                sitekey={process.env.REACT_APP_LANDING_PAGE_CAPTCHA_SITE_KEY}
                size={isFoldedPhone ? 'compact' : 'normal'}
                onChange={handleCaptChaChange}
              />
            </Form.Item>
          </div>

          <div className={styles['button-container']}>
            <Button className={styles['button']} htmlType="submit">
              {t('orderTracking.form.lookup')}
            </Button>
          </div>
        </div>
      </Form>

      <img src={dropShippingImg} alt="drop-shipping-logo" className={styles['tracking-sgv']} />
    </div>
  );
};

export default OrderTrackingForm;

import React from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import CustomCard from '../CustomCard';
import ProdTable from '../ProdTable';

function OrderItems({ rowSelection, orderDetails }) {
  const { t } = useTranslation();
  return (
    <CustomCard
      title={<span className={styles['title-card']}>{t('orderComplain.title.prodComplain')}</span>}
    >
      <p className={`${styles['required']}`}>{t('orderComplain.form.selectItem')}</p>
      <ProdTable rowSelection={rowSelection} orderDetails={orderDetails?.items} />
    </CustomCard>
  );
}

export default OrderItems;

import { gql } from '@apollo/client';

export const GET_DETAIL_COMPLAIN = gql`
  query GetComplainDetail($auth: OrderAuth!) {
    order {
      getComplain(auth: $auth) {
        id
        orderID
        code
        complainEvents {
          id
          event
          complainID
          createdAt
          updatedAt
        }
        items {
          productID
          productName
          productCode
          quantity
          product {
            imageURL
            uom {
              name
            }
            variantAttributeValues {
              attributeID
              attribute {
                name
              }
              attributeValue {
                value
              }
            }
          }
        }
        status
        reason
        description
        demand
        total
        paymentMethod
        attachmentURLs
        createdAt
        updatedAt
      }
      __typename
    }
  }
`;

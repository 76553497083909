import { ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { GWClient } from '../config/apolloClient';
import { TheLayout } from '../containers';
import i18n from '../locales/i18n';
import './App.less';

export function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={GWClient}>
        <Router>
          <TheLayout />
        </Router>
      </ApolloProvider>
    </I18nextProvider>
  );
}
export default App;

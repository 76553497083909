import { Card } from 'antd';
import React from 'react';
import styles from './index.module.scss';

const CustomCard = ({ title, children, style }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <Card style={style}>{children}</Card>
    </div>
  );
};

export default CustomCard;

import { gql } from '@apollo/client';

export const GET_TRACKING_DETAIL = gql`
  query GetTrackingDetail($orderCode: String!, $phoneNumber: String!) {
    order {
      getByCodeAndPhone(orderCode: $orderCode, phoneNumber: $phoneNumber) {
        id
        status
        number
        senderName
        senderPhone
        senderAddress
        receiverContactName
        receiverContactPhone
        receiverContactAddress
        total
        orderType
        paymentMethod
        shippingType
        shippingConfiguration {
          busConfig {
            busName
          }
          deliver3PartyConfig {
            name
            serviceName
          }
        }
        fullEvents(userType: SELLER) {
          state
          name
          createdAt
        }
        items {
          productID
          productName
          productCode
          quantity
          product {
            imageURL
            uom {
              name
            }
            variantAttributeValues {
              attributeID
              attribute {
                name
              }
              attributeValue {
                value
              }
            }
          }
        }
      }
    }
  }
`;

import OrderTracking from '../pages/OrderTracking';
import OrderComplain from '../pages/OrderComplain';
import ReviewComplain from '../pages/ReviewComplain';
import { Redirect } from 'react-router-dom';

export const routes = [
  { path: '/order-tracking/detail', component: OrderTracking, exact: true },
  { path: '/order-tracking', component: OrderTracking, exact: true },
  { path: '/order-tracking/detail/order-complain', component: OrderComplain, exact: true },
  {
    path: '/order-tracking/detail/order-complain/review-complain',
    component: ReviewComplain,
    exact: true,
  },

  { path: '*', render: () => <Redirect to="/order-tracking" /> },
];

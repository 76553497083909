import { Collapse } from 'antd';
import { SvgIcon } from '../../../../assets/svgs';
import React from 'react';
import OrderEvents from '../OrderEvents';
import styles from './index.module.scss';
import { CloseCircleFilled } from '@ant-design/icons';
import { parseIsoDateStringToMoment } from '@monorepo/utils';
import { STATUS_ORDER, EVENT_STATUS } from '@monorepo/config';

const { Panel } = Collapse;

const OrderStatusHeader = ({ orderDetails }) => {
  const lastEvent = orderDetails?.fullEvents?.findLast((item) => item.state === EVENT_STATUS.PAST);

  const parseTimeString = (timestamp) => {
    if (timestamp)
      return parseIsoDateStringToMoment(timestamp).format('hh:mm - DD/MM/YYYY').toString();
    return;
  };

  const isCancelled = orderDetails?.status === STATUS_ORDER.CANCELED;

  return (
    <span className={styles['header']}>
      <span className={styles['icon']}>
        {isCancelled ? (
          <CloseCircleFilled style={{ color: '#d2434d', fontSize: '24px' }} />
        ) : (
          <SvgIcon.DeliverySVG />
        )}
      </span>
      <span className={styles['title']}>
        <div className={styles[`${isCancelled ? 'red' : 'green'}`]}>{lastEvent?.name}</div>
        <div className={styles['time']}>{parseTimeString(lastEvent?.createdAt)}</div>
      </span>
    </span>
  );
};

const OrderEventsMobile = ({ orderDetails }) => {
  return (
    <div className={styles['container']}>
      <Collapse defaultActiveKey={['1']} ghost expandIconPosition="right">
        <Panel header={<OrderStatusHeader orderDetails={orderDetails} />} key="1">
          <OrderEvents orderDetails={orderDetails} />
        </Panel>
      </Collapse>
    </div>
  );
};

export default OrderEventsMobile;

import React, { useEffect } from 'react';
import { formatCurrency, getQuery } from '@monorepo/utils';
import { Button, Image, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import OrderEvents from '../OrderEvents';
import styles from './index.module.scss';
import { useWindowSize } from '@monorepo/hooks';
import OrderEventsMobile from '../OrderEventsMobile';
import { ORDER_TYPE, PAYMENT_METHOD } from '../../constant';
import { useGetTrackingDetail } from '../../../../hooks/TrackingOrder';
import { Spinner } from '@monorepo/shared-components';
import { useHistory, useLocation } from 'react-router-dom';
import { renderVariantAttributes } from '@monorepo/utils';
import { convertDeliveryMethod } from '../../../../utils/helperFunc';

const TrackingDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const USE_QUERY = getQuery();
  const { isMobileResponsive } = useWindowSize();

  const { data: orderDetails, loading } = useGetTrackingDetail({
    orderCode: location?.state?.orderCode,
    phoneNumber: location?.state?.phoneNumber,
  });

  const communicators = [
    {
      title: t('orderTracking.sender'),
      name: orderDetails?.senderName,
      phone: orderDetails?.senderPhone,
      address: orderDetails?.senderAddress,
    },
    {
      title: t('orderTracking.receiver'),
      name: orderDetails?.receiverContactName,
      phone: orderDetails?.receiverContactPhone,
      address: orderDetails?.receiverContactAddress,
    },
  ];

  const columns = [
    {
      title: t('orderTracking.numberOrder'),
      align: 'center',
      width: '10%',
      render: (_, __, index) => index + 1,
    },
    {
      title: t('orderTracking.image'),
      align: 'center',
      dataIndex: ['product', 'imageURL'],
      width: '18%',
      render: (_, record) => <Image width={80} height={80} src={record?.product?.imageURL} />,
    },
    { title: t('orderTracking.prodCode'), align: 'center', dataIndex: 'productCode', width: '15%' },
    { title: t('orderTracking.prodName'), align: 'center', dataIndex: 'productName', width: '20%' },
    {
      title: t('orderTracking.classify'),
      align: 'center',
      dataIndex: ['product', 'variantAttributeValues'],
      width: '20%',
      render: (_, record) => {
        const variantAttributes = record?.product?.variantAttributeValues;
        return renderVariantAttributes(variantAttributes);
      },
    },
    {
      title: t('orderTracking.quantity'),
      align: 'center',
      dataIndex: 'quantity',
      render: (_, record) =>
        `${record?.quantity} ${record?.product?.uom?.name ? record.product.uom.name : 'cái'}`,
    },
  ];

  const convertPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
      case PAYMENT_METHOD.CASH:
        return t('orderTracking.transferOrCash');
      case PAYMENT_METHOD.DEBT:
        return t('orderTracking.debt');
      default:
        return t('orderTracking.no');
    }
  };

  useEffect(() => {
    if (!(location?.state?.orderCode || location?.state?.phoneNumber)) {
      history.replace({
        pathname: '/order-tracking',
        state: USE_QUERY,
      });
    }
  }, []);

  return (
    <div className={styles['container']}>
      <Spinner loading={loading}></Spinner>

      <div className={`${styles['card']} ${styles['order-event']}`}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>{t('orderTracking.orderEvent')}</div>
          {isMobileResponsive && (
            <div className={styles['title-code']}>#{orderDetails?.number}</div>
          )}
        </div>
        {!isMobileResponsive && (
          <div className={styles['code']}>
            <span className={styles['code-title']}>{t('orderTracking.orderCode')}: </span>
            <span className={styles['code-value']}>#{orderDetails?.number}</span>
          </div>
        )}

        {isMobileResponsive ? (
          <OrderEventsMobile orderDetails={orderDetails} />
        ) : (
          <OrderEvents orderDetails={orderDetails} />
        )}

        <div className={styles['button-group']}>
          <Button
            className={styles['button']}
            onClick={() => {
              history.push({
                pathname: '/order-tracking/detail/order-complain',
                search: location.search,
                state: location.state,
              });
            }}
          >
            {t('orderTracking.complain')}
          </Button>
          <a href="https://honganh.vn/lien-he" target="_blank" rel="noreferrer">
            <Button className={`${styles['button']} ${styles['main-button']}`}>
              {t('orderTracking.contactHA')}
            </Button>
          </a>
        </div>
      </div>
      <div className={`${styles['card']} ${styles['order-detail']}`}>
        <div className={styles['title']}>{t('orderTracking.orderInfo')}</div>

        <div className={styles['info']}>
          {communicators.map((communicator, index) => (
            <div key={index}>
              <div className={styles['info-title']}>{communicator.title}</div>
              <div>{communicator.name}</div>
              <div>{communicator.phone}</div>
              <div>{communicator.address}</div>
              <hr />
            </div>
          ))}
          <div className={styles['info-shipping']}>
            <span>
              <div className={styles['info-title']}>{t('orderTracking.shippingUnit')}</div>
              {orderDetails?.orderType === ORDER_TYPE.SELLER && (
                <div className={styles['info-title']}>{t('orderTracking.paymentMethod')}</div>
              )}
            </span>
            <span className={styles['info-shipping-values']}>
              <div>
                {convertDeliveryMethod(
                  orderDetails?.shippingType,
                  orderDetails?.shippingConfiguration
                ) || t('orderTracking.no')}
              </div>
              {orderDetails?.orderType === ORDER_TYPE.SELLER && (
                <div>{convertPaymentMethod(orderDetails?.paymentMethod)}</div>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className={`${styles['card']} ${styles['order-items']}`}>
        <div className={styles['title']}>{t('orderTracking.orderDetailInfo')}</div>
        {!isMobileResponsive && (
          <div className={styles['items-table']}>
            <Table
              columns={columns}
              scroll={{ x: 'none', y: 290 }}
              dataSource={orderDetails?.items}
              pagination={false}
              rowKey={(record) => record.productID}
            ></Table>
          </div>
        )}
        <div className={styles['total']}>
          <span className={styles['total-label']}>{t('orderTracking.orderTotal')}</span>
          <span className={styles['total-value']}>{formatCurrency(orderDetails?.total)}</span>
        </div>
        {isMobileResponsive && (
          <div className={styles['items']}>
            {orderDetails?.items?.map((record, index) => (
              <div className={styles['item-cards']} key={index}>
                <div className={styles['left']}>
                  <span className={styles['title']}>{index + 1}/</span>
                </div>
                <div className={styles['mid']}>
                  <div className={styles['title']}>{record.productName}</div>
                  <i>{record.productCode}</i>
                  <div>{renderVariantAttributes(record.product.variantAttributeValues)}</div>
                </div>
                <div className={styles['right']}>
                  <div className={styles['quantity']}>
                    x {record.quantity} {record.product?.uom?.name || 'cái'}
                  </div>
                  <div>
                    <Image width={80} height={80} src={record.product.imageURL} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackingDetail;

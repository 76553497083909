import { ReactComponent as HacDropShip } from './hac-drop-ship.svg';
import { ReactComponent as DeliverySVG } from './icon-delivery.svg';
import { ReactComponent as ImgSVG } from './icon-image.svg';
import { ReactComponent as HelperSVG } from './icon-help.svg';
import { ReactComponent as AppStoreSVG } from './banner-app-store.svg';
import { ReactComponent as GGstoreSVG } from './banner-gg-store.svg';

const SvgIcon = {
  HacDropShip,
  DeliverySVG,
  ImgSVG,
  HelperSVG,
  AppStoreSVG,
  GGstoreSVG,
};

export { SvgIcon };

import { SHIPPING_TYPE } from '@monorepo/config';
import { t } from 'i18next';

export const convertDeliveryMethod = (shippingType, shippingConfig) => {
  const D3P = [
    shippingConfig?.deliver3PartyConfig?.serviceName,
    shippingConfig?.deliver3PartyConfig?.name,
  ]
    .filter((item) => item)
    .join(' - ');

  switch (shippingType) {
    case SHIPPING_TYPE.BUS:
      return shippingConfig?.busConfig?.busName;
    case SHIPPING_TYPE.DELIVER_3PARTY:
      return D3P;
    case SHIPPING_TYPE.URBAN_COD:
      return t('orderTracking.codName');
    case SHIPPING_TYPE.URBAN_NOT_COD:
      return t('orderTracking.urbanNotCOD');
    case SHIPPING_TYPE.PICK_UP_COUNTER:
      return t('orderTracking.pickUpCounter');
    default:
      return t('orderTracking.no');
  }
};

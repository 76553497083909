import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_TRACKING_DETAIL } from '../../graphql/TrackingOrder/quey';

export const useGetTrackingDetail = (params) => {
  const { loading, data, refetch } = useQuery(GET_TRACKING_DETAIL, {
    variables: params,
    skip: !params?.orderCode && !params?.phoneNumber,
  });
  return {
    loading,
    data: data?.order?.getByCodeAndPhone,
    refetch,
  };
};

export function useLazyGetTrackingDetail() {
  const [getOrderDetail, { loading, data }] = useLazyQuery(GET_TRACKING_DETAIL);
  return {
    data: data?.order?.getByCodeAndPhone,
    loading,
    getOrderDetail,
  };
}

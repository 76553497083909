export const EVENT_STATUS = {
  FUTURE: 'FUTURE',
  CURRENT: 'CURRENT',
  PAST: 'PAST',
};

export const ANTD_STEPS_STATUS = {
  WAIT: 'wait',
  PROCESS: 'process',
  FINISH: 'finish',
  ERROR: 'error',
};

export const PAYMENT_METHOD = {
  CASH: 'CASH',
  DEBT: 'DEBT',
};

export const ORDER_TYPE = {
  SELLER: 'SELLER',
  CUSTOMER: 'CUSTOMER',
};

export const STATUS_COMPLAIN = {
  CREATE: 'CREATE',
  RECEIVING: 'RECEIVING',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
};

export const DEMAND_METHOD = {
  SWAP: 'SWAP',
  REFUND_A_PART: 'REFUND_A_PART',
  BACK_UP_AND_REFUND: 'BACK_UP_AND_REFUND',
  WARRANTY: 'WARRANTY',
};

export const REASON_METHOD = {
  LOW_QUALITY: 'LOW_QUALITY',
  WRONG_ATTRIBUTE: 'WRONG_ATTRIBUTE',
  BROKEN: 'BROKEN',
  FAKE: 'FAKE',
  OUT_DATE: 'OUT_DATE',
  ERROR: 'ERROR',
  SECOND_HAND: 'SECOND_HAND',
  DELAY_DELIVER: 'DELAY_DELIVER',
  INSUFFICIENT: 'INSUFFICIENT',
  WARRANTY_REQUIRED: 'WARRANTY_REQUIRED',
};

export const SHIPPING_TYPE = {
  BUS: 'BUS',
  DELIVER_3PARTY: 'DELIVER_3PARTY',
  PICK_UP_COUNTER: 'PICK_UP_COUNTER',
  URBAN_COD: 'URBAN_COD',
  URBAN_NOT_COD: 'URBAN_NOT_COD',
};

export const convertDemandMethod = (demandMethod) => {
  switch (demandMethod) {
    case DEMAND_METHOD.BACK_UP_AND_REFUND:
      return 'orderComplain.options.request.BACK_UP_AND_REFUND';
    case DEMAND_METHOD.REFUND_A_PART:
      return 'orderComplain.options.request.REFUND_A_PART';
    case DEMAND_METHOD.SWAP:
      return 'orderComplain.options.request.SWAP';
    case DEMAND_METHOD.WARRANTY:
      return 'orderComplain.options.request.WARRANTY';
    default:
      return '';
  }
};

export const convertReasonMethod = (reasonMethod) => {
  switch (reasonMethod) {
    case REASON_METHOD.LOW_QUALITY:
      return 'orderComplain.options.reason.LOW_QUALITY';
    case REASON_METHOD.WRONG_ATTRIBUTE:
      return 'orderComplain.options.reason.WRONG_ATTRIBUTE';
    case REASON_METHOD.BROKEN:
      return 'orderComplain.options.reason.BROKEN';
    case REASON_METHOD.FAKE:
      return 'orderComplain.options.reason.FAKE';
    case REASON_METHOD.OUT_DATE:
      return 'orderComplain.options.reason.OUT_DATE';
    case REASON_METHOD.ERROR:
      return 'orderComplain.options.reason.ERROR';
    case REASON_METHOD.SECOND_HAND:
      return 'orderComplain.options.reason.SECOND_HAND';
    case REASON_METHOD.DELAY_DELIVER:
      return 'orderComplain.options.reason.DELAY_DELIVER';
    case REASON_METHOD.INSUFFICIENT:
      return 'orderComplain.options.reason.INSUFFICIENT';
    case REASON_METHOD.WARRANTY_REQUIRED:
      return 'orderComplain.options.reason.WARRANTY_REQUIRED';
    default:
      return '';
  }
};

export const convertPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case PAYMENT_METHOD.CASH:
      return 'orderComplain.options.refundMethod.CASH';
    case PAYMENT_METHOD.DEBT:
      return 'orderComplain.options.refundMethod.DEBT';
    default:
      return '';
  }
};

export const convertStatusMethod = (statusMethod) => {
  switch (statusMethod) {
    case STATUS_COMPLAIN.CREATE:
      return 'orderComplain.evenstComplain.send';
    case STATUS_COMPLAIN.RECEIVING:
      return 'orderComplain.evenstComplain.receive';
    case STATUS_COMPLAIN.PROCESSING:
      return 'orderComplain.evenstComplain.processing';
    case STATUS_COMPLAIN.COMPLETED:
      return 'orderComplain.evenstComplain.completed';
    default:
      return '';
  }
};

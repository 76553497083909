import React from 'react';
import styles from './index.module.scss';
import './index.scss';
import { Steps } from 'antd';
import { renderCreateAt } from '@monorepo/utils';
import { useWindowSize } from '@monorepo/hooks';

const { Step } = Steps;

const ComplainEvents = ({ orderDetails }) => {
  const { isMobileResponsive } = useWindowSize();

  return (
    <div className={styles['event-steps']}>
      <Steps
        direction="vertical"
        labelPlacement="vertical"
        size={isMobileResponsive ? 'small' : 'default'}
      >
        {orderDetails?.fullEvents?.map((event, index) => (
          <Step
            key={index}
            title={<span className={styles['title']}>{event.name}</span>}
            description={
              <span className={styles['description']}>
                {renderCreateAt(event.createdAt, isMobileResponsive)}
              </span>
            }
          />
        ))}
      </Steps>
    </div>
  );
};

export default ComplainEvents;

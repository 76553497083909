import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '~/routes';

const TheContent = () => {
  return (
    <Switch>
      {routes?.map((item, index) => {
        return <Route key={index} {...item} />;
      })}
    </Switch>
  );
};

export default TheContent;

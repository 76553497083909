import { Badge, Layout, Tabs } from 'antd';
import './index.scss';
import { HomeOutlined, BellOutlined, AppstoreOutlined, UserOutlined } from '@ant-design/icons';
// import { getAppRoutes } from '../../routes';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '../../assets/icons';
import { useState } from 'react';
import { useNotificationList, useTotalUnreadNotifications } from '@monorepo/hooks';

export const LayoutMobile = ({ getAppRoutes }) => {
  const { Content } = Layout;
  const { TabPane } = Tabs;
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { data } = useTotalUnreadNotifications();

  // console.log(data?.notification?.totalUnreadNotifications);

  const comparePathname = (pathname) => pathname === location.pathname;

  const changeIconActive = ({ pathname, iconInActive, iconActive }) =>
    comparePathname(pathname) ? iconActive : iconInActive;
  const tabNavItem = [
    {
      key: '/',
      icon: changeIconActive({
        pathname: '/',
        iconInActive: <SvgIcon.HomeOutlineIcon />,
        iconActive: <SvgIcon.HomeFilledIcon />,
      }),
      title: t('menu.tab.home'),
    },
    {
      key: '/notification',
      icon: changeIconActive({
        pathname: '/notification',
        iconInActive: (
          <Badge count={data} size="small" overflowCount={99}>
            <SvgIcon.BellOutlineIcon />
          </Badge>
        ),

        iconActive: (
          <Badge count={data} size="small" overflowCount={99}>
            <SvgIcon.BellFilledIcon />
          </Badge>
        ),
      }),
      title: t('menu.tab.notification'),
    },
    {
      key: '/application',
      icon: changeIconActive({
        pathname: '/application',
        iconInActive: <SvgIcon.ApplicationOutlineIcon />,
        iconActive: <SvgIcon.ApplicationFilledIcon />,
      }),
      title: t('menu.tab.application'),
    },
    {
      key: '/user',
      icon: changeIconActive({
        pathname: '/user',
        iconInActive: <SvgIcon.UserProfileOutlineIcon />,
        iconActive: <SvgIcon.UserProfileFilledIcon />,
      }),
      title: t('menu.tab.account'),
    },
  ];

  const renderTab = (icon, title) => (
    <>
      {icon}
      <p>{title}</p>
    </>
  );

  const onTabClick = (key) => {
    history.push(`${key}`);
  };

  return (
    <Layout className="layout">
      <Layout className="site-layout">
        <Content className="site-layout-background content">
          {getAppRoutes()?.map((item, index) => {
            return <Route key={index} {...item} />;
          })}
        </Content>

        <Tabs
          type="card"
          className="ant-tabs-bottom"
          animated={{ inkBar: false }}
          onTabClick={onTabClick}
          activeKey={location.pathname}
        >
          {tabNavItem.map((item) => (
            <TabPane key={item.key} tab={renderTab(item.icon, item.title)} />
          ))}
        </Tabs>
      </Layout>
    </Layout>
  );
};

export default LayoutMobile;
